import { Swipe, SwipeItem, Tabs, Tab, Tag, Icon, Image, RadioGroup, Radio } from 'vant'
import Navbar from '../../common/Navbar.vue'
import Share from '../../common/Share.vue'
import Loading from '../../common/Loading.vue'
import Login from '../../intercept/Login.vue'
import LogVisit from '../../common/LogVisit.vue'
import Call from '../../common/Call.vue'
import Clue from '../../common/Clue.vue'
import '../../../../assets/icon/font-icon.js'
export default {
    components: {
        Navbar: Navbar,
        Share: Share,
        Loading: Loading,
        Login: Login,
        LogVisit: LogVisit,
        Call: Call,
        Clue: Clue,
        [Swipe.name]: Swipe,
        [SwipeItem.name]: SwipeItem,
        [Tabs.name]: Tabs,
        [Tab.name]: Tab,
        [Tag.name]: Tag,
        [Icon.name]: Icon,
        [Image.name]: Image,
        [RadioGroup.name]: RadioGroup,
        [Radio.name]: Radio
    },
    data () {
        return {
            code: '',
            loadingShow: false,
            shareShow: false,
            businessList: [{ code: '1', name: '升学' }, { code: '2', name: '转学' }, { code: '3', name: '借读' }],
            business: '',
            detail: {},
            grade: ''
        }
    },
    mounted () {
        var query = this.$route.query
        this.code = query.code
        this.$refs.login.validate()
        this.$refs.share.default('main')
    },
    methods: {
        init () {
            this.$refs.logVisit.createLog()
            this.retrieveSchoolDetail()
        },
        async retrieveSchoolDetail () {
            this.loadingShow = true
            var that = this
            var pd = { code: that.code }
            const { data: res } = await this.$http.post(this.BMS_URL + '/educate/schoolAPC/retrieveSchoolDetail', this.$qs.stringify(pd))
            this.loadingShow = false
            if (res.status === '200') {
                this.detail = res.data
                this.createViewClue()
            }
        },
        async createOrder () {
            this.loadingShow = true
            if (this.business === '' || this.business === null || this.business === undefined) {
                this.$dialog.alert({
                    title: '提示信息',
                    message: '请选择咨询业务'
                })
                this.loadingShow = false
            } else {
                var app = window.sessionStorage.getItem(this.SESSION_APP)
                var user = window.sessionStorage.getItem(this.SESSION_USER)
                var operator = window.sessionStorage.getItem(this.SESSION_OPERATOR)
                var seller = window.sessionStorage.getItem(this.SESSION_SELLER)
                var referee = window.sessionStorage.getItem(this.SESSION_REFEREE)
                var pd = {
                    app: app,
                    schoolCode: this.code,
                    businessCode: this.business,
                    userCode: user,
                    operatorCode: operator,
                    sellerCode: seller,
                    refereeCode: referee
                }
                const { data: res } = await this.$http.post(this.BMS_URL + '/educate/order/createOrder', this.$qs.stringify(pd))
                this.loadingShow = false
                if (res.status === '200') {
                    this.createOrderClue(res.data.code)
                    this.$dialog.alert({
                        title: '咨询申请',
                        message: '咨询申请成功,请耐心等待或到店咨询'
                    }).then(() => {
                        this.back()
                    })
                } else {
                    this.$dialog.alert({
                        title: '申请失败',
                        message: res.msg
                    })
                }
            }
        },
        shareImage () {
            this.$router.push({ path: '/mde/recruit/shareImage', query: { taskCode: this.taskCode } })
        },
        onlineChat () {
            this.$refs.call.callWinShow('true')
        },
        createViewClue () {
            var param = { code: this.code, url: window.location.href }
            var content = '证书考培浏览'
            this.$refs.clue.createClue('EDUCATE_VIEW_SCHOOL', this.code, JSON.stringify(param), content)
        },
        createOrderClue (orderCode) {
            var param = { schoolCode: this.code, businessCode: this.businessCode, orderCode: orderCode, url: window.location.href }
            var content = '证书考培订单'
            this.$refs.clue.createClue('EDUCATE_ORDER_SCHOOL', orderCode, JSON.stringify(param), content)
        },
        back () {
            var backurl = window.sessionStorage.getItem(window.const.global.EDUCATE_DETAIL_INDEX_BACK)
            if (backurl !== undefined && backurl !== null) {
                window.location.href = backurl
            } else {
                this.$router.push({ path: '/mde/cert/home' })
            }
        }
    }
}
